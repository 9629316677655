<template>
  <div>
    <div class="mb-5">
      <v-card class="card-custom-shadow">
        <v-card-text>
          <v-simple-table class="table-padding-2">
            <template v-slot:default>
              <thead class="v-data-table-header">
                <tr>
                  <th role="columnheader" class="text-center">
                    {{ $t("labels.count_sku") }}
                  </th>
                  <th role="columnheader" class="text-center">
                    {{ $t("labels.count_item") }}
                  </th>
                  <th role="columnheader" class="text-center">
                    {{ $t("labels.total_price") }}
                  </th>
                  <th role="columnheader" class="text-center">
                    {{ $t("labels.discount_1") }}
                  </th>
                  <th role="columnheader" class="text-center">
                    {{ $t("labels.amount_after_discount") }}
                  </th>
                  <th role="columnheader" class="text-center">
                    {{ $t("labels.delivery_fee") }}
                  </th>
                  <th role="columnheader" class="text-center">
                    {{ $t("labels.order_prepay") }}
                  </th>
                  <th role="columnheader" class="text-center">
                    {{ $t("labels.order_bank_account") }}
                  </th>
                  <th role="columnheader" class="text-center">
                    {{ $t("labels.bill") }}
                  </th>
                  <th role="columnheader" class="text-center">
                    {{ $t("labels.cod") }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-center">
                  <td>{{ total["totalSku"] }}</td>
                  <td>{{ total["totalItem"] }}</td>
                  <td>{{ total["totalPrice"] }}</td>
                  <td style="max-width: 110px">
                    <input-number
                      :key="updatedKey"
                      class="c-input-small"
                      v-model="discountValue"
                      name="discountValue"
                      type="number"
                      outlined
                      dense
                      hide-details
                      ref="inputDiscount"
                      @input="onDiscountChanged"
                    />
                  </td>
                  <td style="max-width: 110px">
                    <input-number
                      :key="updatedKey"
                      class="c-input-small"
                      v-model="amount"
                      outlined
                      dense
                      hide-details
                      ref="inputAmount"
                      @input="onAmountChanged"
                    />
                  </td>
                  <td style="max-width: 110px">
                    <input-number
                      class="c-input-small"
                      v-model="deliveryFee"
                      outlined
                      dense
                      hide-details
                      :key="updatedKey"
                      @input="onDeliveryFeeChanged"
                    />
                  </td>
                  <td style="max-width: 110px">
                    <input-number
                      class="c-input-small"
                      v-model="prepay"
                      outlined
                      dense
                      hide-details
                      :key="updatedKey"
                      @input="onPrepayChanged"
                    />
                  </td>
                  <td style="max-width: 110px">
                    <v-autocomplete
                      class="c-input-small"
                      v-model="idBankAccount"
                      :items="bankAccountOptions"
                      outlined
                      dense
                      clearable
                      hide-details
                    ></v-autocomplete>
                  </td>
                  <td style="max-width: 110px">
                    <v-file-input
                      accept="image/*"
                      v-model="lead.prepay_image"
                      @change="onUploadBill"
                      @click="onUploading(gKey)"
                      dense
                      multiple
                      outlined
                      hide-details
                      class="c-input-small c-input-file"
                      append-icon="mdi-paperclip"
                      prepend-icon=""
                      :clearable="false"
                    ></v-file-input>
                  </td>
                  <td>
                    {{ formatNumber(finalCod) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </div>

    <div class="mb-5">
      <div>
        <v-card class="mb-5 card-custom-shadow">
          <v-card-text>
            <LeadSelectSuggestItem
              @onSelect="selectItem"
              :select-items="selectItems"
            />
          </v-card-text>

          <v-card-text>
            <div v-if="selectItems.length > 0">
              <v-simple-table
                class="table-padding-2-no-top"
                v-if="goodsItems.length > 0"
              >
                <template v-slot:default>
                  <thead class="v-data-table-header">
                    <tr>
                      <th
                        class="text-center vertical-align-middle"
                        style="width: 50px"
                      >
                        {{ $t("labels.image") }}
                      </th>
                      <th
                        class="text-center vertical-align-middle purple--text"
                      >
                        {{ $t("labels.product") }}
                      </th>
                      <th class="text-center vertical-align-middle">
                        {{ $t("labels.name") }}
                      </th>
                      <th class="text-center vertical-align-middle">
                        {{ $t("labels.goods_description") }}
                      </th>
                      <th class="text-center vertical-align-middle">
                        {{ $t("labels.size") }}
                      </th>
                      <th
                        class="text-center vertical-align-middle"
                        style="width: 75px"
                      >
                        {{ $t("labels.available_quantity_1") }}
                      </th>
                      <th
                        class="text-center vertical-align-middle"
                        style="width: 75px"
                      >
                        {{ $t("labels.quantity") }}
                      </th>
                      <th
                        class="text-center vertical-align-middle"
                        style="width: 90px"
                      >
                        {{ $t("labels.price_0") }}
                      </th>
                      <th
                        class="text-center vertical-align-middle"
                        style="width: 85px"
                      >
                        {{ $t("labels.discount") }}
                      </th>
                      <th
                        class="text-center vertical-align-middle"
                        style="width: 100px"
                      >
                        {{ $t("labels.value") }}
                      </th>
                      <th
                        class="text-center vertical-align-middle"
                        style="width: 75px"
                      >
                        <v-btn
                          color="warning"
                          x-small
                          style="min-width: 20px; height: 20px; padding: 0"
                          @click="clearGoods"
                        >
                          <v-icon style="font-size: 14px">mdi-restore</v-icon>
                        </v-btn>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in goodsItems"
                      :key="`s${item.id_goods}`"
                      class="text-center"
                    >
                      <td>
                        <ImageViewer
                          :url="
                            item.url_images ||
                            require('@/assets/common/no-image.jpg')
                          "
                          width="60px"
                          height="60px"
                        />
                      </td>
                      <td style="width: 105px">
                        {{ item.customer_goods_barcode }}
                      </td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.description }}</td>
                      <td>{{ item.size }}</td>
                      <td>{{ formatNumber(item.available) }}</td>
                      <td style="width: 60px">
                        <v-text-field
                          v-model.number="item.quantity"
                          class="c-input-small"
                          type="number"
                          @keyup="onItemChanged"
                          dense
                          single-line
                          outlined
                          hide-details
                        ></v-text-field>
                      </td>
                      <td>{{ formatNumber(item[item.price_type]) }}</td>
                      <td>
                        <v-select
                          v-model="item.discount_type"
                          :items="discountTypeOptions"
                          class="c-input-small"
                          outlined
                          dense
                          hide-details
                          @change="onItemChanged"
                          :disabled="
                            !checkPermission(['order_create_select_discount'])
                          "
                        ></v-select>
                      </td>
                      <td>
                        <template
                          v-if="
                            item.discount_type &&
                            checkPermission(['order_create_select_discount'])
                          "
                        >
                          <v-text-field
                            v-if="item.discount_type === 1"
                            v-model.number="item.discount_value"
                            max="100"
                            class="c-input-small"
                            type="number"
                            @keyup="onItemChanged"
                            dense
                            single-line
                            outlined
                            hide-details
                          ></v-text-field>
                          <input-number
                            v-else
                            v-model.number="item.discount_value"
                            type="number"
                            @input="onItemChanged"
                            outlined
                            dense
                            hide-details
                            :key="updatedKey"
                            class="c-input-small"
                          />
                        </template>
                      </td>
                      <td>
                        <v-btn
                          color="grey lighten-2"
                          x-small
                          style="min-width: 20px; height: 20px; padding: 0"
                          @click="removeItem(item)"
                        >
                          <v-icon style="font-size: 14px">mdi-close</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

              <v-simple-table
                class="table-padding-2-no-top"
                v-if="comboItems.length > 0"
              >
                <template v-slot:default>
                  <thead class="v-data-table-header">
                    <tr>
                      <th
                        class="text-center vertical-align-middle purple--text"
                      >
                        {{ $t("labels.combo") }}
                      </th>
                      <th class="text-center vertical-align-middle">
                        {{ $t("labels.goods") }}
                      </th>
                      <th
                        class="text-center vertical-align-middle"
                        style="width: 75px"
                      >
                        {{ $t("labels.available_quantity_1") }}
                      </th>
                      <th
                        class="text-center vertical-align-middle"
                        style="width: 75px"
                      >
                        {{ $t("labels.quantity") }}
                      </th>
                      <th
                        class="text-center vertical-align-middle"
                        style="width: 90px"
                      >
                        {{ $t("labels.price_0") }}
                      </th>
                      <th
                        class="text-center vertical-align-middle"
                        style="width: 85px"
                      >
                        {{ $t("labels.discount") }}
                      </th>
                      <th
                        class="text-center vertical-align-middle"
                        style="width: 100px"
                      >
                        {{ $t("labels.value") }}
                      </th>
                      <th
                        class="text-center vertical-align-middle"
                        style="width: 75px"
                      >
                        <v-btn
                          color="warning"
                          x-small
                          style="min-width: 20px; height: 20px; padding: 0"
                          @click="clearCombo"
                        >
                          <v-icon style="font-size: 14px">mdi-restore</v-icon>
                        </v-btn>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in comboItems"
                      :key="`c${item.id}`"
                      class="text-center"
                    >
                      <td style="width: 105px">{{ item.barcode }}</td>
                      <td>
                        <div
                          class="d-flex align-center"
                          v-for="(g, k) in item.goods"
                          :key="`cg_${k}_${g.id}`"
                        >
                          <div>
                            <div>
                              <span
                                class="font-weight-medium"
                                style="color: #b71c1c"
                              >
                                {{ g.customer_goods_barcode }}
                              </span>
                              - {{ $t("labels.sku") }}: {{ g.sku }}
                              <span style="color: #b71c1c"
                                >* {{ g.quantity }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>{{ formatNumber(item.available) }}</td>
                      <td style="width: 60px">
                        <v-text-field
                          v-model.number="item.quantity"
                          class="c-input-number-xs"
                          type="number"
                          dense
                          single-line
                          outlined
                          hide-details
                          @keyup="onItemChanged"
                        ></v-text-field>
                      </td>
                      <td>{{ formatNumber(item[item.price_type]) }}</td>
                      <td>
                        <v-select
                          v-model="item.discount_type"
                          :items="discountTypeOptions"
                          class="c-input-small"
                          outlined
                          dense
                          hide-details
                          @change="onItemChanged"
                          :disabled="
                            !checkPermission(['order_create_select_discount'])
                          "
                        ></v-select>
                      </td>
                      <td>
                        <template
                          v-if="
                            item.discount_type &&
                            checkPermission(['order_create_select_discount'])
                          "
                        >
                          <v-text-field
                            v-if="item.discount_type === 1"
                            v-model.number="item.discount_value"
                            max="100"
                            class="c-input-small"
                            type="number"
                            @keyup="onItemChanged"
                            dense
                            single-line
                            outlined
                            hide-details
                          ></v-text-field>
                          <input-number
                            v-else
                            v-model.number="item.discount_value"
                            type="number"
                            outlined
                            dense
                            hide-details
                            class="c-input-small"
                            :key="updatedKey"
                            @input="onItemChanged"
                          />
                        </template>
                      </td>
                      <td>
                        <v-btn
                          color="grey lighten-2"
                          x-small
                          style="min-width: 20px; height: 20px; padding: 0"
                          @click="removeItem(item)"
                        >
                          <v-icon style="font-size: 14px">mdi-close</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>

            <div v-else>
              {{ $t("labels.not_yet_select_goods") }}
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import { DISCOUNT_TYPE_OPTIONS } from "@/libs/const";
import { debounce } from "lodash/function";

export default {
  name: "LeadDetailItem",
  components: {
    LeadSelectSuggestItem: () =>
      import("@/components/crm/LeadSelectSuggestItem"),
    ImageViewer: () => import("@/components/common/ImageViewer"),
  },
  props: {
    lead: {
      type: Object,
      default: () => {},
    },
    items: {
      type: Array,
      default: () => [],
    },
    actionFrom: {
      type: String,
      default: () => "telesale",
    },
  },
  data: () => ({
    idBankAccount: "",
    bill: "",
    discountValue: "",
    deliveryFee: "",
    prepay: "",
    amount: "",
    bankAccountOptions: [],
    selectItems: [],
    discountTypeOptions: [...DISCOUNT_TYPE_OPTIONS],
    updatedKey: "",
  }),
  computed: {
    goodsItems() {
      return [...this.selectItems].filter((s) => !!s.id_goods);
    },
    comboItems() {
      return [...this.selectItems].filter((s) => !!s.id_combo);
    },
    total() {
      let totalItem = 0;
      let totalPrice = 0;
      let totalSku = 0;
      for (let i = 0; i < this.selectItems.length; i++) {
        const item = this.selectItems[i];
        totalItem += +item.quantity;
        totalPrice += +item.quantity * item.price;
        totalSku++;
      }

      return {
        totalItem,
        totalPrice,
        totalSku,
      };
    },
    finalCod() {
      return this.amount + (this.deliveryFee || 0) - (this.prepay || 0);
    },
  },
  created() {
    this.amount = +this.lead.amount;
    this.discountValue = +this.lead.discount_value;
    this.deliveryFee = +this.lead.delivery_fee;
    this.prepay = +this.lead.prepay;
    this.idBankAccount = this.lead.id_customer_bank_account;

    let isItemDiscounted = false;
    this.selectItems = [...this.items].map((item) => {
      if (item.discount_type) {
        isItemDiscounted = true;
      }

      return {
        ...item,
        id_warehouse: this.lead.id_warehouse,
        id_pos: this.lead.id_pos,
      };
    });

    if (this.amount && !isItemDiscounted) {
      this.onAmountChanged();
    }

    this.getBankAccounts();
  },
  methods: {
    onUploadBill() {},
    changeItemDiscountAfterAmountChanged() {
      const selectItems = [...this.selectItems];
      const totalPrice = this.total["totalPrice"];
      const discountValue = this.discountValue;

      let totalDiscounted = 0;
      for (let i = 0; i < selectItems.length; i++) {
        const item = selectItems[i];
        item.ratio_price = (item.price * item.quantity) / totalPrice;
        item.discount_type = 2;
        if (i === selectItems.length - 1) {
          item.discount_value = discountValue - totalDiscounted;
        } else {
          item.discount_value = Math.floor(item.ratio_price * discountValue);
          totalDiscounted += +item.discount_value;
        }

        selectItems[i] = { ...item };
      }

      this.selectItems = [...selectItems];
      this.updatedKey = this.generateRandomString(10);
    },
    onDiscountChanged: debounce(function () {
      const totalPrice = this.total["totalPrice"];

      if (this.discountValue > totalPrice) {
        this.discountValue = totalPrice;
      }

      this.amount = totalPrice - +this.discountValue;

      this.changeItemDiscountAfterAmountChanged();
      this.updatedKey = this.generateRandomString(10);

      setTimeout(() => {
        this.$refs.inputDiscount.focusInput();
      }, 200);

      this.saveLead();
    }, 1000),
    onAmountChanged: debounce(function () {
      const totalPrice = this.total["totalPrice"];
      if (this.amount > totalPrice) {
        this.amount = totalPrice;
      }

      this.discountValue = totalPrice - +this.amount;

      this.changeItemDiscountAfterAmountChanged();
      this.updatedKey = this.generateRandomString(10);

      setTimeout(() => {
        this.$refs.inputAmount.focusInput();
      }, 200);

      this.saveLead();
    }, 1000),
    onItemChanged: debounce(function () {
      const selectItems = [...this.selectItems];

      // let amount = 0;
      let discountValue = 0;
      for (let i = 0; i < selectItems.length; i++) {
        const item = selectItems[i];
        // item.discount_value = 0;

        let itemDiscounted = 0;
        if (!item.discount_type) {
          item.discount_value = 0;
        } else {
          if (item.discount_type == 1) {
            if (item.discount_value > 100) {
              item.discount_value = 100;
            }
            const itemAmount = +item.price * +item.quantity;
            itemDiscounted = (itemAmount * item.discount_value) / 100;
          } else {
            itemDiscounted = +item.discount_value;
          }
        }

        discountValue += itemDiscounted;

        selectItems[i] = { ...item };
      }

      this.discountValue = discountValue;
      const totalPrice = this.total["totalPrice"];
      this.amount = totalPrice - discountValue;
      this.selectItems = [...selectItems];
      this.updatedKey = this.generateRandomString(10);

      this.saveLead();
    }, 1000),
    /* onItemChanged: debounce(function () {
      // tinh discount & total discount
      this.discountValue = "";
      this.amount = "";
      this.updatedKey = this.generateRandomString(10);
    }, 1000), */
    onDeliveryFeeChanged: debounce(function () {
      this.saveLead();
    }, 1000),
    onPrepayChanged: debounce(function () {
      this.saveLead();
    }, 1000),
    selectItem({ item, item_type }) {
      const sItem = { ...item };
      sItem.price_type = "price";
      sItem.quantity = 1;
      sItem.id_warehouse = this.lead.id_warehouse;
      sItem.id_pos = 0;
      if (item_type == "combo") {
        sItem.id_combo = sItem.id;
      } else {
        sItem.id_goods = sItem.id;
      }
      sItem.id = undefined;
      this.selectItems.push(sItem);

      this.onItemChanged();

      this.saveLead();
    },

    removeItem(item) {
      const selectItems = [...this.selectItems].filter(
        (it) => it.id_goods !== item.id_goods
      );
      this.selectItems = [...selectItems];

      this.saveLead();
    },

    clearGoods() {
      const selectItems = [];
      this.selectItems = [...selectItems].filter((s) => !s.id_goods);

      this.saveLead();
    },

    clearCombo() {
      const selectItems = [];
      this.selectItems = [...selectItems].filter((s) => !s.id_combo);

      this.saveLead();
    },

    saveLead: debounce(function () {
      httpClient
        .post("/crm-lead-telesale-update-items", {
          id: this.lead.id,
          cod: this.finalCod,
          discount_value: this.discountValue,
          amount: this.amount,
          delivery_fee: this.deliveryFee,
          prepay: this.prepay,
          id_customer_bank_account: this.idBankAccount,
          bill: this.bill,
          items: this.selectItems,
          action_from: this.actionFrom,
        })
        .then(({ data }) => {
          console.log(data);
        });
    }, 1000),

    getBankAccounts() {
      httpClient
        .post("/get-all-bank-account", { status: 1 })
        .then(({ data }) => {
          this.bankAccountOptions = [...data].map((b) => ({
            value: b.id,
            text: `STK: ${b.account} - Chủ TK: ${b.owner}`,
          }));
        });
    },
  },
};
</script>

<style scoped lang="scss">
.msg-block {
  width: 100%;
  min-width: 300px;
  padding-top: 3px;
  padding-bottom: 3px;
  height: calc(100vh - 500px);
  overflow-y: auto;
  border: solid 1px black;
  border-radius: 3px;
  background-color: white;
}

.chat-msg {
  margin-bottom: 10px;
}

.chat-name {
  background-color: transparent;
  font-style: italic;
  font-size: 2.5mm;
  color: grey;
}

.chat-type-1 {
  .chat-content {
    padding: 5px;
    width: auto;
    max-width: 70%;
    background-color: lightgrey;
    border: 1px solid transparent;
    border-radius: 10px;
    text-align: left;
    text-wrap: wrap;
    font-size: 13px;
  }
}

.chat-type-2 {
  text-align: right;

  .chat-content {
    padding: 5px;
    border: 1px solid transparent;
    border-radius: 10px;
    background-color: dodgerblue !important;
    color: white !important;
    width: 70%;
    margin-left: 30%;
    text-wrap: wrap;
    font-size: 13px;
  }
}
</style>
